import axios from 'axios'

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

const UNAUTHORIZED = 401

function apiPath(path) {
  return `/api${path}`
}

function goToLogin() {
  if (!/login/.test(location.href)) {
    const { protocol, host } = location
    location.href = `${protocol}//${host}/login`
  }
}

function handleError(e) {
  console.log(e)
  switch (e.response.status) {
    case UNAUTHORIZED:
      return goToLogin()
    default:
      console.error(e)
      return null
  }
}

export default {
  //
  // Programs
  //

  async fetchPrograms() {
    return axios
      .get(apiPath('/programs'))
      .then(resp => resp.data)
      .catch(handleError)
  },

  async fetchProgram(id) {
    return axios
      .get(apiPath(`/programs/${id}`))
      .then(resp => resp.data)
      .catch(handleError)
  },

  createProgram(program) {
    return axios
      .post(apiPath('/programs'), { program })
      .then(resp => resp.data)
      .catch(handleError)
  },

  deleteProgram(id) {
    return axios.delete(apiPath(`/programs/${id}`))
  },

  updateProgram(program) {
    return axios
      .put(apiPath(`/programs/${program.id}`), { program })
      .then(resp => resp.data)
  },

  //
  // Scores
  //
  async retrieveScore(id) {
    return axios
      .get(apiPath(`/scores/${id}`))
      .then(resp => resp.data)
      .catch(handleError)
  },

  async fetchScore(id) {
    return axios
      .get(apiPath(`/scores/${id}`))
      .then(resp => resp.data)
      .catch(handleError)
  },

  async fetchScores() {
    return axios
      .get(apiPath('/scores'))
      .then(resp => resp.data)
      .catch(handleError)
  },

  async createScore(data) {
    return axios
      .post(apiPath(`/scores`), data)
      .then(resp => resp.data)
      .catch(handleError)
  },

  async deleteScore(id) {
    return axios
      .delete(apiPath(`/scores/${id}`))
      .then(resp => resp.data)
      .catch(handleError)
  },

  async saveScore(data) {
    return axios
      .put(apiPath(`/scores/${data.id}`), data)
      .then(resp => resp.data)
      .catch(handleError)
  },

  async uploadMusicXML(filename) {
    return axios
      .post(apiPath('/scores/upload_musicxml'), { filename })
      .then(resp => resp.data)
      .catch(handleError)
  },

  async uploadMidi() {
    return axios
      .post(apiPath('/scores/upload_midi'))
      .then(resp => resp.data)
      .catch(handleError)
  },

  //
  // Students
  //

  async createStudent(student) {
    return axios
      .post(apiPath('/students'), { student })
      .then(resp => resp.data)
      .catch(handleError)
  },

  async retrieveStudents() {
    return axios
      .get(apiPath('/students'))
      .then(resp => resp.data)
  },

  async retrieveStudent(id) {
    return axios
      .get(apiPath(`/students/${id}`))
      .then(resp => resp.data)
  },

  async updateStudent(student) {
    return axios
      .put(apiPath(`/students/${student.id}`), { student })
      .then(resp => resp.data)
  },

  async deleteStudent(id) {
    await axios.delete(apiPath(`/students/${id}`))
  },

  //
  // Users
  //

  async fetchUsers() {
    return axios
      .get(apiPath('/users'))
      .then(resp => resp.data)
      .catch(handleError)
  },

  async updateUser(user) {
    return axios
      .put(apiPath(`/users/${user.id}`), user)
      .then(resp => resp.data)
      .catch(handleError)
  },

  async login(user) {
    try {
      const resp = await axios.post('/auth/login', { user })
      const { redirect_to } = resp.data
      if (redirect_to) return window.location = redirect_to
      return resp.data
    } catch (e) {
      return { error: e.response.data }
    }
  },

  async resetPassword(user) {
    try {
      const resp = await axios.post('/password', { user })
      return resp.data
    } catch (e) {
      return { error: e.response.data }
    }
  },

  async register(user) {
    try {
      console.log('registering user', user)
      const resp = await axios.post('/auth/register', { user })
      return resp.data
    } catch (e) {
      return { error: e.response.data }
    }
  },

  async getCurrentUser() {
    return axios
      .get(apiPath('/users/me'))
      .then(resp => resp.data)
      .catch(handleError)
  },

  //
  // Misc
  //

  uploadFileToS3(uploadUrl, file, headers = {}) {
    return fetch(uploadUrl, { method: "PUT", body: file, headers })
  }
}
